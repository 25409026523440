// @flow
import React from 'react';
import Layout from '../components/layout';
import GlossaryLink from '../components/glossary-link';
import type { FrontMatter } from '../utils/types';
import { withFrontMatter } from '../components/front-matter';

class Canyoneering_Check extends React.Component<{
  frontMatter: FrontMatter,
}> {
  render() {
    const { frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <h2>Everything you need to check before going on rappel</h2>
        <ul>
          <li>
            Clothing should be tucked in to the harness or out of the way so
            that it doesn't get stuck in the{' '}
            <GlossaryLink>rappel device</GlossaryLink>.
          </li>
          <li>
            <GlossaryLink>Helmet</GlossaryLink> chinstrap should be buckled.
          </li>
          <li>Helmet headband thing should be tightened.</li>
          <li>
            <GlossaryLink>Harness</GlossaryLink>{' '}
            <GlossaryLink>waist belt</GlossaryLink> should be tightened. Many
            belts have to be doubled back so make sure it is doubled back if
            required.
          </li>
          <li>
            Harness <GlossaryLink>leg loops</GlossaryLink> should be tightened.
          </li>
          <li>
            <GlossaryLink>Gloves</GlossaryLink> on.
          </li>
          <li>Long hair should be tied back.</li>
          <li>
            If it's the first rappel, make sure the weather is cooperating
            before you commit to the canyon.
          </li>
          <li>
            Be mindful of loose rocks or anything else that could come down on
            the <GlossaryLink>belayer</GlossaryLink>.
          </li>
          <li>
            All <GlossaryLink>carabiners</GlossaryLink> should be locked.
          </li>
          <li>
            The rope should be going through the rappel device properly. If{' '}
            <GlossaryLink id="double-rope-technique">
              double stranding
            </GlossaryLink>{' '}
            with an <GlossaryLink>ATC</GlossaryLink>, make sure both strands are
            around the carabiner.
          </li>
          <li>
            The rappel device should be rigged for adequate starting friction.
            If using an ATC and with the possibility of needing to add more
            friction, add carabiners for a <GlossaryLink>z rig</GlossaryLink>.
          </li>
          <li>
            Check that all <GlossaryLink>knots</GlossaryLink> are properly tied.
          </li>
        </ul>
        <hr />
        <h2>Acronym, please?</h2>
        <p>
          Here you go: <b>CHECK</b> - <b>C</b>lothing <b>H</b>arness/<b>H</b>
          elmet <b>E</b>nvironment <b>C</b>onnections <b>K</b>nots.
        </p>
        <h3>Clothing</h3>
        <p>
          Clothing should be tucked in to the harness or out of the way so that
          it doesn't get stuck in the rappel device.
        </p>
        <h3>Harness/Helmet</h3>
        <p>
          The harness should be correctly tightened - the belt is often doubled
          back on itself so check for that. The leg loops should be tightened as
          well.
        </p>
        <p>
          The helmet chin strap should be buckled and the headband thing
          tightened on the head.
        </p>
        <p>
          You can optionally add <b>H</b>ands and <b>H</b>air to this as well.
          Hands means gloves should be worn. Hair should be tied back if it is
          long. The new acronym would be <b>CHHHHECK</b>.
        </p>
        <p>
          If you're in <b>H</b>eadless <b>H</b>en or <b>H</b>ard <b>D</b>ay{' '}
          <b>H</b>arvey, it would change to <b>CHHHHHHECK</b> or{' '}
          <b>CHHHHHDHECK</b>, respectively.
        </p>
        <h3>Environment</h3>
        <p>
          Verify that the weather is suitable for canyoneering if this is the
          first rappel. Check that there are no loose rocks or things that can
          come down on the belayer. If there are, make sure the belayer knows
          and that the rappeller is mindful of their steps.
        </p>
        <h3>Connections</h3>
        <p>
          All carabiners should be locked (click test). The rope should go
          properly through the rappel device.
        </p>
        <p>
          The Click test is to just squeeze the gate of the carabiner. If it
          clicks, it is locked. If it does not click and opens, it needs to be
          locked.
        </p>
        <h3>Knots</h3>
        <p>
          <GlossaryLink>Anchor</GlossaryLink> should be{' '}
          <GlossaryLink>EARNEST</GlossaryLink> and knots should be checked
          before the first person rappels. Otherwise check that any knots are
          correctly tied. This includes any{' '}
          <GlossaryLink>autoblock</GlossaryLink>,{' '}
          <GlossaryLink>Valdotain Tresse</GlossaryLink>, or{' '}
          <GlossaryLink>top-rope belay</GlossaryLink> knots.
        </p>
        <hr />
        <h2>Another acronym - ABCDs before each rappel</h2>
        <p>
          Another one I have heard with a similar goal is <b>ABCD</b>:
        </p>
        <p>
          <b>A</b>irway <b>B</b>reathing ... just kidding, it's:
        </p>
        <ul>
          <li>
            <b>A</b>nchor - Check that it's EARNEST - necessary only the first
            time someone rappels (part of Knots).
          </li>
          <li>
            <b>B</b>uckles - check that the harness waist and leg loops are
            properly buckled (part of Harness/Helmet).
          </li>
          <li>
            <b>C</b>arabiner - all carabiners should be locked (click test)
            (part of Connections).
          </li>
          <li>
            <b>D</b>escender - The rope should go properly through the rappel
            device (part of Connections).
          </li>
        </ul>
        <hr />
        <h2>Credits</h2>
        <p>
          I first heard <b>CHECK</b> from the GSLCBSACIC (Great Salt Lake
          Council Boy Scouts of America Climbing Instructor Course).
        </p>
        <p>
          I can't remember where I first heard the <b>ABCDs</b> but if you want
          credit for it tell me you invented it and I'll tell everyone you were
          the genius behind it.
        </p>
      </Layout>
    );
  }
}

export default withFrontMatter('check')(Canyoneering_Check);
